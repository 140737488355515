/** 产品详情 */
<template>
  <div class="product-details">
    <section :class="['back-fff', $isPc?'pb-30':'plr-10']">
      <div class="content">
        <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/serve/product' }">融资服务</el-breadcrumb-item>
          <el-breadcrumb-item>{{product.product_name}}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 基本信息 -->
        <div v-if="Object.keys(product).length" :class="['product-basic', 'flex',{'flex-wrap':!$isPc}]">
          <div class="info-img flex-center">
            <el-image class="border-r4" :src="$base+product.product_logo" fit="contain">
            </el-image>
            <div v-if="!$isPc" class="flex-1">
              <h4 class="info-title">{{product.product_name}}</h4>
              <p>参考范围：<strong>{{product.interest_scope_min}}%-{{product.interest_scope_max}}%</strong></p>
            </div>
          </div>
          <div :class="['flex-column', 'justify-between',$isPc?'flex-1':'']">
            <div v-if="$isPc" class="justify-between align-center">
              <h4 class="info-title ">{{product.product_name}}</h4>
              <span class="info-browse-num"><i class="el-icon-view"></i>{{product.browse_num}}</span>
            </div>
            <ul type="flex" :class="['product-info-list', {'flex-wrap':$isPc}]">
              <li v-if="$isPc" class="product-info-item">
                <span> 参考范围：</span>
                <strong>{{product.interest_scope_min}}%-{{product.interest_scope_max}}%</strong>
              </li>
              <li class="product-info-item">
                <span>服务机构：</span>
                <strong>{{product.agency}}</strong>
              </li>
              <li class="product-info-item">
                <span>融资担保期限：</span><strong>{{product.finance_date}}月</strong>
              </li>
              <li class="product-info-item">
                <span>最高担保额度：</span><strong>{{product.finance_lines_max}}万</strong>
              </li>
              <li class="product-info-item">
                <span>适用范围：</span><strong>{{product.apply_area}}</strong>
              </li>
            </ul>
            <el-button v-if="$isPc" class="product-apply-btn" type="primary" @click="applyBtn">在线申请</el-button>
          </div>
        </div>
      </div>
    </section>
    <section :class="['justify-between', 'content', {'ptb-30':$isPc}]">
      <!-- 详情 -->
      <div class="details-left flex-1">
        <el-row class="details-con mb-30">
          <el-col>
            <h4 class="details-title">产品介绍：</h4>
            <div class="details-desc" v-html="product.product_introduct"></div>
          </el-col>
          <el-col>
            <h4 class="details-title">产品适用对象：</h4>
            <div class="details-desc" v-html="product.product_object"></div>
          </el-col>
          <el-col>
            <h4 class="details-title">提交材料：</h4>
            <div class="details-desc" v-html="product.product_material"></div>
          </el-col>
        </el-row>
      </div>
      <!-- 更多相关推荐 -->
      <div v-if="$isPc && productOther.length" class="details-right flex-1 ml-20">
        <div class="details-other back-fff border-r8">
          <p class="details-other-title">相关推荐</p>
          <ul class="details-other-list">
            <li class="details-other-item cursor-pointer ptb-20" v-for="(item,index) in productOther" :key="index">
              <router-link :to="{ path: '/product_details', query: { id: item.id } }">
                <div class="other-basic flex">
                  <el-image class="info-img border-r4" :src="$base+item.product_logo" fit="contain">
                  </el-image>
                  <div class="other-info flex-column justify-between flex-1">
                    <h4 class="info-title text-ellipsis-lines">{{item.product_name}}</h4>
                    <el-row class="product-info-list">
                      <el-col class="product-info-item text-ellipsis">
                        融资担保期限：<strong>{{item.finance_date}}月</strong>
                      </el-col>
                      <el-col class="product-info-item text-ellipsis">
                        最高担保额度：<strong>{{item.finance_lines_max}}万</strong>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section v-if="!$isPc" class="zixun flex">
      <div class="zuo flex-1 flex justify-around flex-center">
        <a :href="`tel:${this.$store.getters.configs.hotline_phone}`" class="flex-column">
          <i class="el-icon-phone"></i>
          <span>客服热线</span>
        </a>
        <el-divider direction="vertical"></el-divider>
        <div @click="$router.push('/counsel')" class="flex-column">
          <i class="el-icon-service"></i>
          <span>在线咨询</span>
        </div>
      </div>
      <router-link class="apply-btn" :to="{ path: '/product_form', query: { id: product.id } }">
        <el-button type="primary">立即申请</el-button>
      </router-link>
    </section>
    <ProductApply :id="product.id" />
  </div>
</template>
<script>
import { getProductDetails, setReadProduct, getProductOther } from '@/api/product'
import ProductApply from './apply.vue'
import { mapActions } from 'vuex'
export default {
  name: 'ProductDetails',
  components: {
    ProductApply
  },
  data () {
    return {
      product: {},//文章详情
      productOther: [],//相关文章
    }
  },
  watch: {
    '$route.query.id': {
      handler (newVal, oldVal) {
        this.getProductDetails()
      },
      deep: true
    }
  },
  mounted () {
    this.getProductDetails()
  },
  methods: {
    ...mapActions([
      'setShowApply',
    ]),
    // 产品详情
    async getProductDetails () {
      try {
        let { data } = await getProductDetails(this.$route.query.id)
        this.product = data || {}
        this.getProductOther()
        this.setReadProduct()
      } catch (error) { }
    },
    // 增加浏览量
    async setReadProduct () {
      try {
        await setReadProduct(this.$route.query.id)
      } catch (error) { }
    },
    // 相关推荐
    async getProductOther () {
      try {
        let { data } = await getProductOther(this.product.finance_lines_max, this.product.finance_date)
        this.productOther = data
      } catch (error) { }
    },

    // 立即申请
    applyBtn () {
      this.id = this.product.id
      this.setShowApply(true)
    },
  },
}
</script>
<style lang="scss" scoped>
.product-details {
  min-height: 100%;
  background-color: #f4f6fa;
}
.details-con {
  & > .el-col {
    margin-bottom: 50px;
    .details-title {
      font-size: 20px;
      margin: 0 0 10px;
    }
    .details-desc {
      line-height: 24px;
      color: #293041;
    }
  }
}
.product-info-list {
  .product-info-item {
    color: #767676;
    strong {
      color: #293041;
    }
  }
}
.product-basic {
  .info-img {
    width: 200px;
    margin-right: 40px;
  }
  .info-title {
    font-size: 24px;
  }
  .info-browse-num {
    min-width: 60px;
    text-align: right;
    color: #b3bfd9;
    i {
      margin-right: 10px;
    }
  }
  .product-info-list {
    margin: 20px -10px;
    .product-info-item {
      display: flex;
      margin: 5px 10px;
    }
  }
  .product-apply-btn {
    width: 130px;
  }
}
.details-other-list {
  background-color: #fff !important;
  .details-other-item {
    .info-img {
      width: 100px;
      max-height: 100px;
      margin-right: 18px;
    }
    .product-info-list {
      .product-info-item {
        margin: 10px 0 0;
      }
    }
  }
}
.zixun {
  height: 37px;
  padding: 10px;
  font-size: 15px;
  width: 95%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  i {
    text-align: center;
    margin-bottom: 5px;
    color: #464646;
    font-size: 16px;
  }
  span {
    font-size: 12px;
  }
  .el-divider--vertical {
    margin: 0;
  }
  .apply-btn {
    width: 40%;
    margin-left: 20px;
    .el-button {
      width: 100%;
      font-size: 12px;
    }
  }
}
@media (max-width: 767px) {
  .details-con {
    & > .el-col {
      margin-bottom: 20px;
    }
  }
  .product-basic {
    .info-img {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-top: 20px;
      .el-image {
        max-width: 40%;
        margin-right: 20px;
      }
    }
    .info-title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
    .product-info-list {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .product-info-item {
        width: 50%;
        margin: 10px 0;
        font-size: 14px;
        strong {
          flex: 1;
        }
      }
    }
  }
}
</style>